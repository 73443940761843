<template>
  <div v-html="svgContent"></div>
</template>

<script>
export default {
  props: {
    fetchUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      svgContent: '',
    };
  },
  mounted() {
    this.fetchSvg();
  },
  methods: {
    async fetchSvg() {
      try {
        const response = await fetch(this.fetchUrl);
        this.svgContent = await response.text();
      } catch (error) {
        console.error('Error fetching SVG:', error);
      }
    },
  },
};
</script>

<style>
svg {
  height: auto;
}
</style>