<template>
  <v-app>
    <SvgAnnotator />
  </v-app>
</template>

<script>
import SvgAnnotator from './components/SvgAnnotator.vue';

export default {
  name: 'App',
  components: {
    SvgAnnotator
  }
};
</script>

<style>
@import '~vuetify/dist/vuetify.min.css';
</style>
