<template>
  <v-container fluid class="svg-annotator" >
    <v-row w-100>
      <v-col cols="12" md="6">
        <v-form>
          <!-- Form and controls -->
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model.number="width" label="Width" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model.number="height" label="Height" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-color-picker hide-canvas hide-inputs show-swatches swatches-max-height="50px" v-model="backgroundColor" label="Background Color" flat></v-color-picker>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="backgroundImage" label="Background Image URL" placeholder="https://wallpapercave.com/wp/tYllGW0.jpg"></v-text-field>
              <v-btn @click="fillPlaceholderText" class="ma-2" color="primary">Use Example</v-btn>
              <v-btn @click="clearPlaceholderText" class="ma-2" color="primary">Use Solid BG</v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-btn @click="addOverlay('circle')" class="ma-2" color="primary">Add Circle</v-btn>
          <v-btn @click="addOverlay('arrow')" class="ma-2" color="primary">Add Arrow</v-btn>
          <v-btn @click="addOverlay('text')" class="ma-2" color="primary">Add Text</v-btn>
          <v-divider class="my-4"></v-divider>
          <v-expansion-panels>
            <v-expansion-panel v-for="(overlay, index) in overlays" :key="index">
              <v-expansion-panel-header>
                <span>{{ overlay.type.charAt(0).toUpperCase() + overlay.type.slice(1) }} Overlay {{ index + 1 }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col v-if="overlay.type === 'circle'" cols="12">
                    <v-text-field v-model.number="overlay.cx" label="Circle X" type="number"></v-text-field>
                    <v-text-field v-model.number="overlay.cy" label="Circle Y" type="number"></v-text-field>
                    <v-text-field v-model.number="overlay.r" label="Radius" type="number"></v-text-field>
                    <v-text-field v-model="overlay.text" label="Text"></v-text-field>
                    <v-select v-model="overlay.state" :items="['animated', 'error', 'success']" label="State"></v-select>
                    <v-color-picker v-model="overlay.color_start" label="Color Start" flat></v-color-picker>
                    <v-color-picker v-model="overlay.color_end" label="Color End" flat></v-color-picker>
                    <v-text-field v-model="overlay.dur" label="Duration"></v-text-field>
                  </v-col>
                  <v-col v-if="overlay.type === 'arrow'" cols="12">
                    <v-text-field v-model.number="overlay.x1" label="Start X" type="number"></v-text-field>
                    <v-text-field v-model.number="overlay.y1" label="Start Y" type="number"></v-text-field>
                    <v-text-field v-model.number="overlay.x2" label="End X" type="number"></v-text-field>
                    <v-text-field v-model.number="overlay.y2" label="End Y" type="number"></v-text-field>
                    <v-color-picker v-model="overlay.color" label="Color" flat></v-color-picker>
                    <v-text-field v-model.number="overlay.strokeWidth" label="Stroke Width" type="number"></v-text-field>
                  </v-col>
                  <v-col v-if="overlay.type === 'text'" cols="12">
                    <v-text-field v-model.number="overlay.x" label="X" type="number"></v-text-field>
                    <v-text-field v-model.number="overlay.y" label="Y" type="number"></v-text-field>
                    <v-text-field v-model="overlay.text" label="Text"></v-text-field>
                    <v-text-field v-model.number="overlay.fontSize" label="Font Size" type="number"></v-text-field>
                    <v-color-picker v-model="overlay.color" label="Color" flat></v-color-picker>
                  </v-col>
                </v-row>
                <v-btn @click="removeOverlay(index)" class="ma-2" color="red">Remove</v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider class="my-4"></v-divider>
          <v-btn @click="generateSVG" class="ma-2" color="success">Generate SVG</v-btn>
          <v-sheet>Not everything is reactive, so you may need to click this button to update the SVG.</v-sheet>
          
        </v-form>
      </v-col>
      <v-col cols="12" md="6">
        <!-- Generated SVG -->
        <div v-if="svgUrl">
          <h3>Generated SVG</h3>
          <v-text-field v-model="svgUrl" readonly></v-text-field>
          <v-btn @click="copyToClipboard" class="ma-2" color="blue">Copy to Clipboard</v-btn>
          <!-- <div v-html="svgContent"></div> -->
          <div>
            <DynamicSvg :fetchUrl="svgUrl" :key="svgUrl" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//   import axios from 'axios';
  import DynamicSvg from './DynamicSvg.vue';
  
  export default {
    components: {
      DynamicSvg
    },
    watch: {
      backgroundColor() {
        this.generateSVG();
      }
    },
    data() {
      return {
        width: 600,
        height: 400,
        backgroundColor: '#ffffff',
        backgroundImage: '',
        overlays: [],
        svgUrl: ''
      };
    },
    methods: {
      colorChange(color) {
        console.log("Color: ", color);
        this.generateSVG();
      },
      fillPlaceholderText() {
        this.backgroundImage = 'https://wallpapercave.com/wp/tYllGW0.jpg';
        this.generateSVG();
      },
      clearPlaceholderText() {
        this.backgroundImage = '';
        this.generateSVG();
      },
      addOverlay(type) {
        if (type === 'circle') {
          this.overlays.push({
            type: 'circle',
            cx: 250,
            cy: 250,
            r: 50,
            text: '',
            state: 'animated',
            color_start: '#0000ff',
            color_end: '#add8e6',
            dur: '2s'
          });
        } else if (type === 'arrow') {
          this.overlays.push({
            type: 'arrow',
            x1: 100,
            y1: 100,
            x2: 200,
            y2: 200,
            color: '#000000',
            strokeWidth: 2
          });
        } else if (type === 'text') {
          this.overlays.push({
            type: 'text',
            x: 100,
            y: 100,
            text: 'Sample Text',
            fontSize: 24,
            color: '#ff0000'
          });
        }
        this.generateSVG();
      },
      removeOverlay(index) {
        this.overlays.splice(index, 1);
        this.generateSVG();
      },
      async generateSVG() {
        const params = {
          width: this.width,
          height: this.height,
          backgroundColor: this.backgroundColor,
          backgroundImage: this.backgroundImage,
          overlays: this.overlays
        };
        const encodedParams = encodeURIComponent(JSON.stringify(params));
        const host = window.location.host;
        const protocol = window.location.protocol;
        this.svgUrl = `${protocol}//${host}/api/generateSvg?input=${encodedParams}`;
      },
      copyToClipboard() {
        const textField = document.createElement('textarea');
        textField.innerText = this.svgUrl;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        alert('URL copied to clipboard');
      }
    }
  };
  </script>
  
  <style>
  .svg-annotator {
    margin: 0 auto;
  }
  svg {
    border: 2px dashed black;
  }
  </style>
  